import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from "react";
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {Loading, SignUpTableInputContainer, SignUpTitle} from "./SignUpTable.styles";
import { SingUpTableProps } from "../../types/types";
import "./SignUpTable.styles.css";
import {useTranslation} from "react-i18next";

const SignUpTable = () => {

    const apiUrl = "https://api.eternitycraft.de/api";
    const [rowData, setRowData] = useState<SingUpTableProps[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const [colDefs] = useState<ColDef[]>([
        { field: "number", headerName: "ID", editable: false, maxWidth: 67 },
        { field: "discord_name", headerName: "Discord Name", editable: true, sortable: false },
        { field: "minecraft_name", headerName: "Minecraft Name", editable: true, sortable: false },
        { field: "beisteuerung", headerName: "Contribution", editable: true, sortable: false },
        { field: "extra_information", headerName: "Information", editable: true, sortable: false, wrapText: true, autoHeight: true },
    ]);

    const defaultColDef = {
        flex: 1,
        minWidth: 400,
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Get data from the server
    const fetchData = async () => {
        fetch(apiUrl + '/data')
            .then(response => response.json())
            .then(data => setRowData(data))
            .then(() => {setLoading(false)})
            .catch(() => setLoading(true));
    };

    // Post data to the server
    const handleCellEditingStopped = (event: any) => {
        const updatedRowData = [...rowData];
        updatedRowData[event.node.rowIndex] = event.data;

        setRowData(updatedRowData);
        fetch(apiUrl + '/data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedRowData),
        })
            .then(response => response.json())
            .then(() =>  {setLoading(false)})
            .catch(() => {setLoading(true)});
    };

    return (
        <SignUpTableInputContainer>
            <SignUpTitle className="animate__animated animate__fadeInDown">{t('signUp')}</SignUpTitle>
            <div className="ag-theme-mycustomtheme animate__animated animate__fadeInUp">
                <AgGridReact
                    columnDefs={colDefs}
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    onCellEditingStopped={handleCellEditingStopped}
                    alwaysShowHorizontalScroll={true}

                />
            </div>
            <div className="animate__animated animate__fadeInDown animate__delay-1s">
                {loading ?
                    (<Loading>{t('dataLoading')}</Loading>) :
                    (<Loading>{t('dataUpToDate')}</Loading>)
                }
            </div>
        </SignUpTableInputContainer>
    );
};

export default SignUpTable;
